















































import { HabilitationsMembre } from '@/models';
import { HABILITATIONSTATE, HABILITATIONTYPE } from '@/enums';
import { Component, Vue } from 'vue-property-decorator';
import { Membre } from '@/components';

@Component({
  components: { Membre }
})
export default class Habilitations extends Vue {
  private membresInterne: HabilitationsMembre[] = [
    {
      prenom: 'Yamm',
      nom: 'Zebosse',
      email: 'ceo@jaji.fr',
      habilitation: HABILITATIONTYPE.TITULAIRE,
      statut: HABILITATIONSTATE.ACTIF
    },
    {
      prenom: 'Joe',
      nom: 'Alvès',
      email: 'joe.Alves@jaji.fr',
      habilitation: HABILITATIONTYPE.ADMIN,
      statut: HABILITATIONSTATE.ACTIF
    },
    {
      prenom: 'Joe',
      nom: 'Alvès',
      email: 'joe.Alves@jaji.fr',
      habilitation: HABILITATIONTYPE.ADMIN,
      statut: HABILITATIONSTATE.WAITING
    },
    {
      prenom: 'Joe',
      nom: 'Alvès',
      email: 'joe.Alves@jaji.fr',
      habilitation: HABILITATIONTYPE.ADMIN,
      statut: HABILITATIONSTATE.ACTIF
    },
    {
      prenom: 'Joe',
      nom: 'Alvès',
      email: 'joe.Alves@jaji.fr',
      habilitation: HABILITATIONTYPE.ADMIN,
      statut: HABILITATIONSTATE.ACTIF
    },
    {
      prenom: 'Joe',
      nom: 'Alvès',
      email: 'joe.Alves@jaji.fr',
      habilitation: HABILITATIONTYPE.ADMIN,
      statut: HABILITATIONSTATE.ACTIF
    }
  ];
  private membresExterne: HabilitationsMembre[] = [
    {
      prenom: 'Existing',
      nom: 'User',
      email: 'tarte.empion@klesiamut.fr',
      habilitation: HABILITATIONTYPE.TITULAIRE,
      statut: HABILITATIONSTATE.WAITING
    },
    {
      prenom: 'Random',
      nom: 'Name',
      email: 'tarte.empion@klesiamut.fr',
      habilitation: HABILITATIONTYPE.ADMIN,
      statut: HABILITATIONSTATE.ACTIF
    }
  ];
}
